.Lab {
  width: 100%;
  display: block;
  position: relative;
}

.LabContent {
  width: 90%;
  display: block;
  position: relative;
  margin: 0 auto;
  text-align: left;
}
.LabNav ul {
  list-style: none;
  padding: 0px;
  text-align: left;
}

.LabNav ul li {
  display: inline-block;
  position: relative;
  color: #353535;
  padding: 20px 20px;
}

.LabNav ul li > a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}
.LabContent .LabNav,
.LabContent .LabText {
  display: inline-block;
}

.LabInfo {
  width: 95%;
  display: block;
  position: relative;
  margin: 20px 0px;
  background-color: #199acc;
  border-radius: 4px;
  padding: 10px 10px;
  text-align: center;
  color: #ffffff;
}

.LabContent h3 {
  margin-top: 0px;
}

.h2Content {
  background-color: #fff;
  padding: 20px;
}
.h2Content:last-child {
  margin-bottom: 0px;
}

.h3Content {
  background-color: #fff;
  padding: 20px;
}

.stepH2 {
  padding: 5px 10px;
  margin-bottom: 10px;
}
.LabInfo > h1 {
  margin: 0px;
}

.LabNav {
  width: 20%;
  vertical-align: top;
}

.LabNav li {
  list-style: none;
  text-align: left;
  color: #001d4a;
}
.LabText {
  width: 70%;
  margin-top: 20px;
  margin-left: 5%;
  background-color: #f3f3f3;
  padding: 10px;
}

.LabText p {
  font-size: 14px;
  line-height: 20px;
}

.LabText h2 {
  margin-top: 0px;
}
.LabTags {
  margin: 0px;
}

.LabNav nav {
  vertical-align: top;
  width: 20%;
  margin-right: 50px;
}

.LabNav li:first-child {
  margin-top: 20px;
}
.LabNav li a {
  text-decoration: none;
  color: #fff;
  padding: 5px 15px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 65%;
}

.arrowDown {
  width: 20px;
  display: inline-block;
  position: relative;

  vertical-align: baseline;
  margin-bottom: 5px;
}
pre {
  font-size: 12px;
}
.step {
  padding: 8px 0px;
  background-color: #199acc;
  border-bottom: 2px solid #ffffff;
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
}

.subStep {
  padding: 8px 0px;
  background-color: #199acc;
  border-bottom: 2px solid #ffffff;
  margin-left: 40px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .LabContent > .LabNav {
    display: none;
    position: relative;
    visibility: hidden;
  }
  .LabText {
    width: 95%;
    margin-left: 0px;
  }
}
